<template>
  <v-dialog
    v-model="signInDialog"
    persistent
    @click:outside="$toasted.global.error('로그인 해 주세요')"
  >
    <v-card max-width="350px" class="mx-auto px-3" rounded="lg">
      <v-card-title class="font-weight-bold headline pt-10">
        <span class="mx-auto"> 로그인 </span>
      </v-card-title>
      <v-card-subtitle class="pt-5 d-flex">
        <span class="mx-auto text-center">
          로그인 시간이 만료되었습니다. <br />
          새로 로그인 후 이용해주세요.
        </span>
      </v-card-subtitle>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!-- SNS 계정 로그인 -->
          <v-card-actions class="justify-center pa-0 pt-5">
            <span
              class="text-center headline-1 font-weight-bold pr-4 mx-0"
              style="color: #767579"
            >
              SNS 계정으로 로그인
            </span>
            <!-- signInWithGoogle -->
            <v-btn @click="signInWithGoogle" depressed icon>
              <v-avatar size="30" color="surface">
                <v-img :src="require('@/assets/logos/auth/google.png')" />
              </v-avatar>
            </v-btn>
            <!-- signInWithKakao -->
            <v-btn @click="signInWithKakao" icon depressed>
              <v-avatar size="30" color="#fee500">
                <v-img :src="require('@/assets/logos/auth/kakao.png')" />
              </v-avatar>
            </v-btn>
            <!-- signInWithNaver -->
            <v-btn @click="signInWithNaver" icon depressed>
              <v-avatar size="30" color="#00c93b">
                <v-img :src="require('@/assets/logos/auth/naver.png')" />
              </v-avatar>
            </v-btn>
          </v-card-actions>

          <v-card-actions class="pa-0">
            <v-divider></v-divider>
            <v-subheader>or</v-subheader>
            <v-divider></v-divider>
          </v-card-actions>

          <!-- 이메일 로그인  -->
          <v-card-text class="pa-0 pb-3">
            <div class="font-weight-bold my-2">이메일</div>
            <v-text-field
              label="user@example.com"
              v-model="form.email"
              :rules="[rule.required, rule.email]"
              required
              hide-details
              single-line
              flat
              dense
              outlined
            />
            <div class="font-weight-bold my-2 mt-4">비밀번호</div>
            <v-text-field
              :label="'비밀번호'"
              style="ime-mode: disabled"
              v-model="form.password"
              :rules="[rule.required]"
              type="password"
              required
              hide-details
              single-line
              flat
              dense
              outlined
            />
            <div class="text-center pt-2">
              <v-btn
                @click="signInWithEmail"
                color="primary"
                width="120px"
                :disabled="!valid"
                depressed
                large
                block
                class="font-weight-bold body-2 mt-5 rounded-lg"
              >
                로그인
              </v-btn>
              <div class="pt-5">
                <span
                  class="subtitle-2 primary--text"
                  @click="$router.push('/page/')"
                >
                  홈으로
                </span>
              </div>
            </div>
          </v-card-text>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "../../API/auth";
export default {
  props: { signInDialog: Boolean },
  data() {
    return {
      valid: false,
      loginLoading: false,
      form: {
        email: "",
        password: "",
      },
      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        email: (v) => /.+@.+/.test(v) || "이메일 형식에 맞지 않습니다.",
      },
    };
  },
  methods: {
    // Google 로그인
    async signInWithGoogle() {
      this.loginLoading = true;
      API.signInWithGoogle().catch(() => (this.loginLoading = false));
    },
    // Kakao 로그인
    signInWithKakao() {
      API.signInWithKakao();
    },
    // Naver 로그인
    signInWithNaver() {
      API.signInWithNaver();
    },
    // Firebase 로그인
    async signInWithEmail() {
      if (!this.$refs.form.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
      this.loginLoading = true;
      API.signInWithFirebase(this.form.email, this.form.password).catch(
        () => (this.loginLoading = false),
      );
    },
  },
};
</script>

<style></style>
