<template>
  <!-- 어드민 사이드바 -->
  <v-navigation-drawer
    class="surface elevation-0"
    app
    hide-overlay
    v-model="drawer"
    width="280px"
    height="100%"
  >
    <v-list nav dense flat>
      <!-- 상단 로고 및 텍스트 -->
      <v-list-item>
        <div class="d-flex mx-auto py-7">
          <!-- 로고이미지 들어가는곳 -->
          <v-avatar size="32">
            <v-img contain :src="require('@/assets/logos/madeall_icon.png')" />
          </v-avatar>
          <!-- 관리자페이지 텍스트 -->
          <span class="headline font-weight-bold my-auto pl-2"> MADE ALL </span>
        </div>
      </v-list-item>
      <v-divider class="mx-7"></v-divider>
      <div class="d-flex py-6">
        <span
          class="title mx-auto grey--text font-weight-regular text--darken-1"
        >
          관리자 페이지
        </span>
      </div>

      <!-- 이동 탭 -->
      <v-list-item-group
        v-model="group"
        mandatory
        class="py-10"
        active-class="primary--text text--accent-4 active-class"
      >
        <v-list-item
          @click="routerPush(item.to)"
          v-for="item in items"
          :key="item.title"
          class="pl-8"
        >
          <v-list-item-avatar size="32">
            <feather size="18" :type="item.icon" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn
      text
      v-if="
        $store.getters['auth/user/GET_CLAIMS'] &&
        $store.getters['auth/user/GET_CLAIMS'].level == 0
      "
      class="ml-5"
      @click="routerPush('Dev-Test')"
    >
      개발자 전용 테스트
    </v-btn>

    <!-- 하단 로고 및 텍스트 -->
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="px-9 transparent"
      style="position: absolute; bottom: 50px; width: 100%"
      flat
    >
      <v-card-title class="py-2 px-2">
        <span class="caption"> {{ new Date() | moment("YYYY.MM") }} </span>
      </v-card-title>
      <v-card-text class="py-2 px-2 text-center">
        <span
          class="primary--text display-1 font-weight-bold"
          style="
            background: transparent;
            background: linear-gradient(to right, #36d1dc, #5b86e5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
        >
          {{
            comma(serverData.today.totalSales - serverData.firstDay.totalSales)
          }}
        </span>
      </v-card-text>
      <v-divider class="my-5"></v-divider>
      <v-row class="text-center body-2 grey--text text--darken-1">
        <v-col cols="6">
          <div class="d-flex pt-1 pb-4">
            <span class="headline mx-auto">
              {{ serverData.today.todayNewOrder }}
            </span>
          </div>
          신규주문
        </v-col>
        <v-col cols="6">
          <div class="d-flex pt-1 pb-4">
            <span class="headline mx-auto">
              {{ serverData.today.todayConfirmedOrder }}</span
            >
          </div>
          작업 중
        </v-col>
      </v-row>
    </v-card>

    <!-- 모바일에서 사이드바 닫기 -->
    <template v-slot:append>
      <div v-if="$vuetify.breakpoint.smAndDown" class="mx-4 my-2 text-end">
        <v-btn
          @click="drawer = !drawer"
          class="grey--text"
          icon
          :ripple="false"
        >
          <feather type="chevron-left" />
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<style scoped>
.active-class {
  border-radius: 0px !important;
  box-shadow: 4px 0 0 0 #0090ff inset;
}
</style>
<script>
export default {
  props: {
    propDrawer: Boolean,
    serverData: Object,
  },
  data() {
    return {
      group: null,
      items: [
        { title: "주문 일지", icon: "grid", to: "Admin-Dashboard" },
        { title: "회원 관리", icon: "users", to: "Admin-Users" },
        {
          title: "통합 주문 관리",
          icon: "truck",
          to: "Admin-Orders",
        },
        { title: "일정 관리", icon: "calendar", to: "Admin-Calendar" },
        { title: "포트폴리오 관리", icon: "archive", to: "Admin-Portfolio" },
        { title: "소식 관리", icon: "bookmark", to: "Admin-Press" },
        { title: "홈페이지 돌아가기", icon: "log-out", to: "Home" },
      ],
    };
  },
  mounted() {
    this.items.filter((v, index) => {
      if (v.to == this.$route.name) return (this.group = index);
    });
  },
  computed: {
    drawer: {
      get() {
        return this.propDrawer;
      },
      set(newVal) {
        this.$emit("update:drawer", newVal);
      },
    },
  },
  methods: {
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
    comma(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style></style>
