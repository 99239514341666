<template>
  <div>
    <admin-appbar />
    <router-view />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import AdminAppbar from "@/components/Appbar/AdminAppbar.vue";
export default {
  components: {
    AdminAppbar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
