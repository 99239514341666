<template>
  <div>
    <v-app-bar color="background" flat app height="64">
      <!-- 좌측 사이드바 열기 -->
      <v-btn @click="drawer = !drawer" class="black--text" icon>
        <feather type="sidebar" />
      </v-btn>

      <v-spacer />
      <span class="pr-3">
        로그인 유효 시간 : {{ tokenExpTime | moment("mm분 ss초") }}</span
      >

      <!-- 우측 프로필 버튼 -->
      <v-menu
        v-if="$store.getters['auth/user/GET_USER']"
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        max-width="250"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="font-weight-regular"
            icon
            :ripple="false"
          >
            <v-avatar size="36" color="surface">
              <v-img
                :src="$store.getters['auth/user/GET_USER'].photoURL"
                style="border: 2px solid #999999"
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-card v-if="$store.getters['auth/user/GET_USER']" class="surface">
          <v-list dense class="pa-0 surface">
            <v-list-item two-line>
              <v-list-item-avatar>
                <v-avatar color="grey lighten-2" size="48">
                  <v-img
                    v-if="$store.getters['auth/user/GET_USER']"
                    :src="$store.getters['auth/user/GET_USER'].photoURL"
                  />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold body-2">
                  {{ $store.getters["auth/user/GET_USER"].displayName }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-regular caption">
                  {{ $store.getters["auth/user/GET_USER"].email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="$store.dispatch('auth/user/SET_LOGOUT', 'Home')"
            >
              <v-list-item-avatar>
                <feather size="18" type="log-out" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold body-2">
                  로그아웃
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- <v-divider></v-divider> -->
    <admin-navigation-drawer
      :propDrawer="drawer"
      :serverData="serverData"
      v-on:update:drawer="
        (v) => {
          drawer = v;
        }
      "
    />
    <sign-in-dialog :signInDialog="signInDialog" />
  </div>
</template>

<script>
import SignInDialog from "../Authorization/signInDialog.vue";
import AdminNavigationDrawer from "../Drawer/AdminNavigationDrawer.vue";
export default {
  components: { AdminNavigationDrawer, SignInDialog },
  data: () => ({
    menu: false,
    drawer: true,
    signInDialog: false,
    tokenExpTime: "",

    serverData: {},
  }),
  async created() {
    this.$axios
      .get("/dashboard/sales", { params: { Search: "dashboard" } })
      .then(({ data }) => {
        this.serverData = data;
      });
    setInterval(() => {
      if (this.$store.getters["auth/user/GET_CLAIMS"]) {
        const tokenExp = new Date(
          this.$store.getters["auth/user/GET_CLAIMS"].exp * 1000,
        ).getTime();
        const nowMillis = new Date().getTime();
        if (tokenExp > nowMillis) {
          this.tokenExpTime = new Date(tokenExp - nowMillis);
          this.signInDialog = false;
        } else {
          this.tokenExpTime = "만료됨";
          this.$store.dispatch("auth/user/SET_LOGOUT");
          this.signInDialog = true;
        }
      }
    }, 1000);
  },
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>

<style></style>
